import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Sumo Deadlift High Pulls 5×2`}</p>
    <p>{`then,`}</p>
    <p>{`15-Deadlifts (275/185)(RX+ 315/205)`}</p>
    <p>{`30-Ring Dips`}</p>
    <p>{`12-Deadlifts`}</p>
    <p>{`24-Ring Dips`}</p>
    <p>{`9-Deadlifts`}</p>
    <p>{`18-Ring Dips`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      